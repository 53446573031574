<template>
  <AuthCard :title="$t('auth.resetPasswordView.title')" @submit="onSubmit">
    <v-card-text class="pb-0">
      <VxTextField
        v-model="form.password"
        :label="$t('auth.resetPasswordView.passwordField.label')"
        name="password"
        type="password"
        autocomplete="off"
        :placeholder="$t('auth.resetPasswordView.passwordField.placeholder')"
        rules="required"
      />
      <VxTextField
        v-model="form.confirmPassword"
        :label="$t('auth.resetPasswordView.confirmPasswordField.label')"
        name="confirmPassword"
        type="password"
        autocomplete="off"
        :placeholder="
          $t('auth.resetPasswordView.confirmPasswordField.placeholder')
        "
        rules="required|confirmed:password"
      />
    </v-card-text>
    <template #links>
      <v-spacer />
      <VxBtn class="px-0" text small @click="onLogin">
        {{ $t("auth.resetPasswordView.goToLoginText") }}
      </VxBtn>
    </template>
  </AuthCard>
</template>

<script>
import axios from "axios";
const $http = axios.create({
  baseURL: ``,
});

import AuthCard from "./components/AuthCard.vue";
import { VxTextField, VxBtn } from "@/core-ui";
import i18n from "@/plugins/vue-i18n";

export default {
  name: "ResetPasswordView",
  components: { AuthCard, VxTextField, VxBtn },
  data: () => ({
    form: { password: null, confirmPassword: null },
  }),
  beforeRouteEnter(to, _, next) {
    if (to.query.reset_password_token) {
      return next();
    }

    return next({
      name: "auth.login",
      params: {
        alert: i18n.t("auth.resetPasswordView.resetPasswordAlert"),
      },
    });
  },
  methods: {
    async onSubmit({ values, setErrors }) {
      try {
        await $http.put("api/users/password", {
          user: {
            password: values.password,
            password_confirmation: values.confirmPassword,
            reset_password_token: this.$route.query.reset_password_token,
          },
        });

        this.$router.push({ name: "auth.login" });
      } catch {
        setErrors([this.$t("auth.resetPasswordView.failedActionText")]);
      }
    },
    onLogin() {
      this.$router.push({ name: "auth.login" });
    },
  },
};
</script>
